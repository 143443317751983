import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import HeadTags from '../../shared/HeadTags';
import { Navigation, Autoplay } from 'swiper';
import { useGetMainPageQuery } from 'services/api/api';
import 'swiper/css';
import main_catalog_1 from 'assets/images/main_catalog_1.jpg';
import main_catalog_2 from 'assets/images/main_catalog_2.jpg';
import main_catalog_3 from 'assets/images/main_catalog_3.jpg';
import main_catalog_4 from 'assets/images/main_catalog_4.jpg';
import main_catalog_5 from 'assets/images/main_catalog_5.jpg';
import main_list_1 from 'assets/images/main_list_1.jpg';
import main_list_2 from 'assets/images/main_list_2.jpg';
import main_list_3 from 'assets/images/main_list_3.jpg';
import main_list_4 from 'assets/images/main_list_4.jpg';
// import img_0803 from 'assets/images/img_0803.jpg';
// import img_0905 from 'assets/images/may19_18.png';
import parse from 'html-react-parser';
import LoadingBlock from "shared/LoadingBlock";
import ym from 'react-yandex-metrika';

const partners = [
  { src: require('assets/images/partners/1.jpg'), href: '/mezhkomnatnye-dveri/ekoshpon/dveri-schlager' },
  { src: require('assets/images/partners/2.jpg'), href: '/mezhkomnatnye-dveri/ekoshpon/dveri-cordondoor' },
  { src: require('assets/images/partners/3.jpg') },
  { src: require('assets/images/partners/4.jpg'), href: '/mezhkomnatnye-dveri/dveri-iz-massiva/oka' },
  { src: require('assets/images/partners/5.jpg'), href: '/mezhkomnatnye-dveri/ekoshpon/dveri-dubrava-sibir' },
  { src: require('assets/images/partners/6.jpg'), href: '/vhodnye-dveri/dveri-kova' },
  { src: require('assets/images/partners/7.jpg'), href: '/vhodnye-dveri/dveri-ferroni' },
  { src: require('assets/images/partners/8.jpg'), href: '/vhodnye-dveri/dveri-elporta' },
  { src: require('assets/images/partners/9.jpg'), href: '/vhodnye-dveri/dveri-oiko' },
  { src: require('assets/images/partners/10.jpg'), href: '/vhodnye-dveri/dveri-bastion' },
];

const HomePage = ({openModal}: {openModal?: (state: string) => void}) => {
  const { data, error, isLoading } = useGetMainPageQuery();
  const location = useLocation();

  useEffect(() => {
    ym('hit', `/`);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [location]);

  return (
    <LoadingBlock isLoading={isLoading}>
      <>
        <HeadTags
          title={data && data.seo?.title ? data.seo.title : 'Купить двери в Орле'}
          metaDescription={data && data.seo?.description ? data.seo.description : 'Двери от ведущих производителей. На рынке более 10 лет. ЗАМЕР, ДОСТАВКА, УСТАНОВКА'}
        />
        <div className="main-top">
          <div className="container">
            <p className="main-top__title">{data?.first_screen_top_title}</p>
            <p>{data?.first_screen_first_subtitle}<br />{data?.first_screen_second_subtitle}</p>
            <p className="uppercase main-top__link" onClick={() => openModal('Вызвать замерщика')}>{data?.first_screen_third_subtitle}</p>
          </div>
        </div>
        <section className="main-catalog container">
          <h1>{data?.main_catalog_h1}</h1>
          {/* <p><img src={img_0905} style={{width: '100%'}} alt="Поздравление с Днем Победы" title="с Днем Победы!"/></p> */}
          {/* <p><img src={img_0803} style={{width: '100%'}} alt="Поздравление с 8 Марта!" title="Поздравление с 8 Марта!"/></p> */}
          {data?.main_catalog_p1 && parse(data?.main_catalog_p1)}
          <ul className="main-catalog__list">
            <li>
              <Link to="/vhodnye-dveri" className="main-catalog__item">
                <img src={main_catalog_1} alt="Входные двери" title="Входные двери" />
                <span className="main-catalog__item-name">Входные двери</span>
              </Link>
            </li>
            <li>
              <Link to="/mezhkomnatnye-dveri" className="main-catalog__item">
                <img src={main_catalog_2} alt="Межкомнатные двери" title="Межкомнатные двери" />
                <span className="main-catalog__item-name">Межкомнатные двери</span>
              </Link>
            </li>
            <li>
              <Link to="/mezhkomnatnye-dveri/vlagostojkie-mezhkomnatnye-dveri" className="main-catalog__item">
                <img src={main_catalog_3} alt="Влагостойкие двери" title="Влагостойкие двери" />
                <span className="main-catalog__item-name">Влагостойкие двери</span>
              </Link>
            </li>
            <li>
              <Link to="/razdvizhnye-peregorodki" className="main-catalog__item">
                <img src={main_catalog_4} alt="Раздвижные перегородки" title="Раздвижные перегородки" />
                <span className="main-catalog__item-name">Раздвижные перегородки</span>
              </Link>
            </li>
            <li>
              <Link to="/furnitura" className="main-catalog__item">
                <img src={main_catalog_5} alt="Дверная фурнитура" title="Дверная фурнитура" />
                <span className="main-catalog__item-name">Дверная фурнитура</span>
              </Link>
            </li>
          </ul>
          {data?.main_catalog_p2 && parse(data?.main_catalog_p2)}
        </section>
        <div className="main-steps">
          <section className="container">
            <h2>{data?.main_steps_h2}</h2>
            <ol className="main-steps__list">
              <li>{data?.main_steps_step1 && parse(data?.main_steps_step1)}</li>
              <li>{data?.main_steps_step2 && parse(data?.main_steps_step2)}</li>
              <li>{data?.main_steps_step3 && parse(data?.main_steps_step3)}</li>
              <li>{data?.main_steps_step4 && parse(data?.main_steps_step4)}</li>
            </ol>
          </section>
        </div>
        <div className="gray">
          <section className="main-services container">
            <h3 className="h2">{data?.main_services_h2}</h3>
            <ul className="main-services-list">
              <li>
                <img src={main_list_1} alt="Замер и установка" title="Замеры могут осуществляться, как в пределах города, так и за городом, в нерабочее время и в выходные дни" />
                <strong>Замер и установка</strong>
                {data?.main_services_s1 && parse(data?.main_services_s1)}
              </li>
              <li>
                <img src={main_list_2} alt="Доставка" title="Самовывоз, Доставка Транспортом компании" />
                <strong>Доставка</strong>
                {data?.main_services_s2 && parse(data?.main_services_s2)}
              </li>
              <li>
                <img src={main_list_3} alt="Гарантия" title="Предоставляем гарантию на 1 год" />
                <strong>Гарантия</strong>
                {data?.main_services_s3 && parse(data?.main_services_s3)}
              </li>
              <li>
                <img src={main_list_4} alt="Удобные варианты оплаты" title="Клиенты могут оплатить заказы наличными, безналичными (по карте)" />
                <div>
                  <strong>Удобные варианты оплаты</strong>
                  {data?.main_services_s4 && parse(data?.main_services_s4)}
                </div>
              </li>
            </ul>
          </section>
          <section className="main-partners container">
            <h3>Наши партнеры</h3>
            <div className="main-partners-list">
              <Swiper
                spaceBetween={0}
                loop={true}
                modules={[Autoplay, Navigation]}
                autoplay={{
                  disableOnInteraction: false,
                }}
                navigation={{
                  nextEl: '#next',
                  prevEl: '#prev',
                }}
                breakpoints={{
                  480: {
                    slidesPerView: 3,
                  },
                  640: {
                    slidesPerView: 4,
                  },
                  900: {
                    slidesPerView: 6,
                  },
                }}
              >
                {partners.map((el, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="slick-slide">
                        {el.href
                          ? <Link to={el.href} className="main-partners-list__item">
                            <img alt="Наши партнеры - фото" src={el.src} title="Наши партнеры" />
                          </Link>
                          : <div className="main-partners-list__item">
                            <img alt="Наши партнеры - фото" src={el.src} title="Наши партнеры" />
                          </div>
                        }
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <div className="swiper-button-prev" id="prev"></div>
              <div className="swiper-button-next" id="next"></div>
            </div>
          </section>
        </div>
      </>
    </LoadingBlock>
  )
};
export default HomePage;