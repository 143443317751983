import { useEffect, useState } from "react";
import HeadTags from "../../shared/HeadTags";
import { useNavigate, Link } from "react-router-dom";
import { useGetSubProductsListQuery } from "services/api/api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import CatalogListItem from "features/Catalog/CatalogListPage/CatalogListItem";
import map_img from "assets/images/map_img.jpg";
import location_pin from "assets/images/location-pin.png";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";

import entrance_5 from "assets/images/entrance_5.jpg";
import entrance_10 from "assets/images/entrance_10.png";

import setupImg_01 from "assets/images/entrance_setup_01.png";
import setupImg_02 from "assets/images/entrance_setup_02.png";
import setupImg_03 from "assets/images/entrance_setup_03.png";
import setupImg_04 from "assets/images/entrance_setup_04.png";
import setupImg_05 from "assets/images/entrance_setup_05.png";

import ym from "react-yandex-metrika";

const EntranceDoorsPage = () => {
  const navigate = useNavigate();
  const slug = window.location.pathname;
  const { data: categoriesList, isLoading } = useGetSubProductsListQuery(slug);
  const [itemsList, setItemsList] = useState({});

  useEffect(() => {
    ym("hit", `/vhodnye-dveri`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [navigate]);

  useEffect(() => {
    let list = {};
    if (categoriesList) {
      categoriesList.map((category) => {
        list[category.subcategory_slug] = category.products;
      });
      setItemsList(list);
    }
  }, [categoriesList]);

  return (
    <>
      <HeadTags
        title="Купить входные двери с доставкой и установкой в Орле - компания Комфорт+"
        metaDescription="В магазине «Комфорт +» в наличии более 100 моделей дверей стандартных размеров. Это варианты для дверной коробки шириной 86 см и 96 см, с открыванием в правую или левую сторону. Их можно заказать в подходящем дизайне: подобрать внутреннюю отделку и нужный цвет."
      />
      <div className="entrance_top">
        <section className="container">
          <h1>Входные двери в Орле в наличии и на заказ</h1>
          <p>
            Входная дверь в современном жилище - не просто элемент комнаты,
            который должен защищать от незваных гостей. Дверь должна обладать
            тепло и звукоизоляцией, вписываться в интерьер, быть надежной и
            долговечной.
          </p>
          <p>
            В магазине «Комфорт +» в наличии более 100 моделей дверей
            стандартных размеров. Это варианты для дверной коробки шириной 86 см
            и 96 см, с открыванием в правую или левую сторону. Их можно заказать
            в подходящем дизайне: подобрать внутреннюю отделку и нужный цвет.
          </p>
          <div className="rubric_links">
            <Link
              to="/vhodnye-dveri/dveri-russidor"
              className="rubric_links__item"
            >
              Двери по фабрикам
            </Link>
            <Link
              to="/vhodnye-dveri/byudzhetnye-dveri"
              className="rubric_links__item"
            >
              Бюджетные двери
            </Link>
            <Link
              to="/vhodnye-dveri/dveri-s-termorazryvom"
              className="rubric_links__item"
            >
              Двери с терморазрывом
            </Link>
            <Link
              to="/vhodnye-dveri/protivopozharnye-dveri"
              className="rubric_links__item"
            >
              Противопожарные двери
            </Link>
          </div>
          <p>
            Также можно купить входную дверь нестандартных размеров для частных
            домов или промышленных помещений высотой от 1800 до 2300 см.
            <br />
            Вариант, подходящий для непохожего интерьера смежных комнат. Полотна
            с разных сторон можно сделать любых цветов и фактур, добавить
            отделку или зеркала.
          </p>
        </section>
      </div>
      <section className="entrance_rubric container">
        <h2>
          <Link to="/vhodnye-dveri/byudzhetnye-dveri">Бюджетные двери</Link>
        </h2>
        <p>
          Недорогие входные металлические двери производства КНР подойдут для
          дачи или в качестве временного варианта при строительстве дома.
          Невысокая цена складывается за счет массового производства и недорогих
          материалов. Простой дизайн, классические расцветки в коричневых и
          черных оттенках.
        </p>
        {itemsList["byudzhetnye-dveri"] ? (
          <div className="rubric_slider">
            <Swiper
              spaceBetween={20}
              modules={[Navigation]}
              loop={true}
              autoplay={{
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: "#next1",
                prevEl: "#prev1",
              }}
              breakpoints={{
                380: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
            >
              {itemsList["byudzhetnye-dveri"].length
                ? itemsList["byudzhetnye-dveri"].map((product, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <CatalogListItem key={product.id} product={product} />
                      </SwiperSlide>
                    );
                  })
                : "Загрузка ..."}
            </Swiper>
            <div className="swiper-button-prev" id="prev1"></div>
            <div className="swiper-button-next" id="next1"></div>
          </div>
        ) : null}
        <Link
          to="/vhodnye-dveri/byudzhetnye-dveri"
          className="rubric__btn rubric__btn--big"
        >
          Смотреть больше дверей
        </Link>
      </section>
      <div className="entrance_rubric entrance_rubric--white">
        <section className="rubric__grid container">
          <h2>
            <Link to="/vhodnye-dveri/dveri-kova">
              Утепленные входные
              <br /> металлические двери
            </Link>
          </h2>
          <div className="rubric__text">
            <p>
              Чтобы тепло быстро не уходило из помещения, можно заказать
              установку входной двери с двух или трехконтурным утеплением.
              Контур утепления - это лента из резины или силикона, которая
              крепится по краям дверного проема. Благодаря вставкам дверь
              закрывается плотнее и мягче, обеспечивается тепло и шумоизоляция.
            </p>
            <p>
              Двухконтурное утепление - это дверь, в которой лента клеится по
              периметру самого полотна и по периметру коробки. Такой вариант
              недорогой с хорошими показателями теплоизоляции.
            </p>
            <p>
              При трехконтурном утеплении один уплотнитель клеится на коробку, а
              на само полотно - два. Такие двери лучше сохраняют тепло, но стоят
              дороже.
            </p>
          </div>
          <img
            src={entrance_5}
            className="rubric__img"
            alt="Утепленные входные металлические двери"
            title="Утепленные входные металлические двери"
          />
          {itemsList["dveri-kova"] ? (
          <div className="rubric_slider">
            <Swiper
              spaceBetween={20}
              modules={[Navigation]}
              loop={true}
              autoplay={{
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: "#next1",
                prevEl: "#prev1",
              }}
              breakpoints={{
                380: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
            >
              {itemsList["dveri-kova"].length
                ? itemsList["dveri-kova"].map((product, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <CatalogListItem key={product.id} product={product} />
                      </SwiperSlide>
                    );
                  })
                : "Загрузка ..."}
            </Swiper>
            <div className="swiper-button-prev" id="prev1"></div>
            <div className="swiper-button-next" id="next1"></div>
          </div>
        ) : null}
          <Link
            to="/vhodnye-dveri/dveri-kova"
            className="rubric__btn rubric__btn--big"
          >
            Смотреть больше дверей
          </Link>
        </section>
      </div>
      <section className="rubric__grid entrance_rubric entrance_rubric--gray container">
        <h2>
          <Link to="/vhodnye-dveri/dveri-s-termorazryvom">
            Двери с терморазрывом
          </Link>
        </h2>
        <div className="rubric__text">
          <p>
            Терморазрыв - это слой между внешним и внутренним полотном
            металлической двери, который не позволяет им соприкасаться, а значит
            обмениваться теплом. Благодаря этому дверь не промерзает, не
            образуется конденсат. Такие входные двери могут быть с разными
            видами утеплителя, листы для полотен изготавливают в основном из
            стали и алюминия.
          </p>
        </div>
        <img
          src={entrance_10}
          className="rubric__img"
          alt="Двери с терморазрывом"
          title="Двери с терморазрывом"
        />
        <Link
          to="/vhodnye-dveri/dveri-s-termorazryvom"
          className="rubric__btn rubric__btn--big"
        >
          Смотреть больше дверей
        </Link>
      </section>
      <div className="entrance_rubric entrance_rubric--white2">
        <section className="container">
          <h3 className="h2">
            <Link to="/vhodnye-dveri/dveri-oiko">
              Двери с повышенной акустической изоляцией (ACOUSTIC)
            </Link>
          </h3>
          <p>
            Полотно толще 45 мм. Внутри находятся шумопоглощающие материалы,
            например, древесно-волокнистая плита. Или же такая дверь
            изготавливается из нескольких листов ДСП, между которыми установлена
            агломерированная пробка.
          </p>
          {itemsList["dveri-oiko"] ? (
          <div className="rubric_slider">
            <Swiper
              spaceBetween={20}
              modules={[Navigation]}
              loop={true}
              autoplay={{
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: "#next1",
                prevEl: "#prev1",
              }}
              breakpoints={{
                380: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
            >
              {itemsList["dveri-oiko"].length
                ? itemsList["dveri-oiko"].map((product, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <CatalogListItem key={product.id} product={product} />
                      </SwiperSlide>
                    );
                  })
                : "Загрузка ..."}
            </Swiper>
            <div className="swiper-button-prev" id="prev1"></div>
            <div className="swiper-button-next" id="next1"></div>
          </div>
        ) : null}
          <Link
            to="/vhodnye-dveri/dveri-oiko"
            className="rubric__btn rubric__btn--big"
          >
            Смотреть больше дверей
          </Link>
        </section>
      </div>
      <section className="entrance_rubric container">
        <h3 className="h2">
          <Link to="/vhodnye-dveri/protivopozharnye-dveri">
            Противопожарные двери
          </Link>
        </h3>
        <p>
          Это дверь, которая сможет до 60 минут задержать распространение огня,
          горячего и холодного дыма из помещения. Внутри полотна находятся
          теплоизоляционные материалы. Их количество и последовательность,
          обеспечивают нужный предел огнестойкости. По периметру полотна клеится
          термоуплотнительная лента. Такую дверь можно дополнить специальным
          “окошком”. Это вариант для организаций, работающих по ночам, например,
          аптек.
          <br />
          Современные противопожарные двери выполняют свою основную функцию -
          защищают от огня, при этом удобны в использовании и эстетичны.
        </p>
        
        {itemsList["protivopozharnye-dveri"] ? (
          <div className="rubric_slider">
            <Swiper
              spaceBetween={20}
              modules={[Navigation]}
              loop={true}
              autoplay={{
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: "#next1",
                prevEl: "#prev1",
              }}
              breakpoints={{
                380: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
            >
              {itemsList["protivopozharnye-dveri"].length
                ? itemsList["protivopozharnye-dveri"].map((product, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <CatalogListItem key={product.id} product={product} />
                      </SwiperSlide>
                    );
                  })
                : "Загрузка ..."}
            </Swiper>
            <div className="swiper-button-prev" id="prev1"></div>
            <div className="swiper-button-next" id="next1"></div>
          </div>
        ) : null}
        <Link
          to="/vhodnye-dveri/protivopozharnye-dveri"
          className="rubric__btn rubric__btn--big"
        >
          Смотреть больше дверей
        </Link>
      </section>
      <div className="entrance_rubric entrance_rubric--white2">
        <section className="container">
          <h3 className="h2">
            <Link to="/vhodnye-dveri/metallicheskie-tehnicheskie-dveri">
              Технические двери
            </Link>
          </h3>
          <p>
            Такие двери прочные, оснащены встроенным запирающим механизмом,
            иногда доводчиком. Чаще всего устанавливаются на предприятиях или
            организациях в складских и служебных помещениях. Могут быть с одной
            створкой или двумя. Такие входные двери изготавливаются под
            конкретный размер проема. Технические двери сочетают надежность
            конструкции, приемлемую стоимость и хорошие показатели эксплуатации.
          </p>
          <Link
            to="/vhodnye-dveri/metallicheskie-tehnicheskie-dveri"
            className="rubric__btn rubric__btn--big"
          >
            Смотреть больше дверей
          </Link>
        </section>
      </div>
      <div className="entrance_setup">
        <section className="container">
          <h3 className="h2">Купить входную дверь в Орле с установкой</h3>
          <p>
            Установка входной двери в квартире или доме — популярная услуга в
            Орле. Профессионалы компании качественно установят дверь, что
            обеспечит отсутствие перекосов дверного блока, продлит срок службы
            петель и замков.
          </p>
          <p>
            Замеры можно сделать самостоятельно или вызвать нашего мастера.
            Обращение к специалисту Комфорт+ поможет избежать ошибок в замерах и
            несоответствия размеров дверного блока проёму. Стоимость установки
            входной двери обсуждается с менеджером и остаётся неизменной после
            согласования.
            <br />
            Жителям г. Орла, которые планируют установить входные двери в своем
            доме или квартире, стоит внимательно ознакомиться с перечнем работ,
            включенных в данную услугу. Отдельно оплачиваются демонтаж старой
            двери, расширение проема и отделка откосов.
          </p>
          <div className="entrance_setup__steps">
            <h3 className="h2">
              Этапы установки входной двери: пошаговое руководство
            </h3>
            <ul className="entrance_setup__list">
              <li>
                <span className="entrance_setup__img">
                  <img src={setupImg_01} alt="Демонтаж старой двери" />
                </span>
                <span className="entrance_setup__sub">
                  Демонтаж старой двери
                </span>
                <p>
                  Прежде чем установить новую дверь, необходимо аккуратно
                  демонтировать старую.
                </p>
                <p>
                  Этот процесс включает несколько шагов, чтобы не повредить
                  стены и проём.
                </p>
              </li>
              <li>
                <span className="entrance_setup__img">
                  <img src={setupImg_02} alt="Подготовка проёма" />
                </span>
                <span className="entrance_setup__sub">Подготовка проёма</span>
                <p>
                  Перед установкой двери убедитесь, что проём чист и готов к
                  монтажу.
                </p>
                <p>Очистите его от пыли и мусора, проверьте ровность стен.</p>
                <p>
                  При необходимости выровняйте стены штукатуркой или другими
                  материалами.
                </p>
              </li>
              <li>
                <span className="entrance_setup__img">
                  <img src={setupImg_03} alt="Установка дверной коробки" />
                </span>
                <span className="entrance_setup__sub">
                  Установка дверной коробки
                </span>
                <p>
                  Один из самых важных этапов монтажа. От правильности установки
                  зависит долговечность и функциональность двери.{" "}
                </p>
                <p>
                  Перед установкой коробки убедитесь, что она соответствует
                  размерам проёма.{" "}
                </p>
                <p>
                  При необходимости подрежьте коробку. Нанесите
                  гидроизоляционную ленту на нижнюю часть коробки и убедитесь,
                  что все элементы коробки находятся в хорошем состоянии
                </p>
              </li>
              <li>
                <span className="entrance_setup__img">
                  <img src={setupImg_04} alt="Монтаж дверного полотна" />
                </span>
                <span className="entrance_setup__sub">
                  Монтаж дверного полотна
                </span>
                <p>
                  После установки коробки можно приступать к монтажу дверного
                  полотна. Этот этап требует особой точности, чтобы дверь
                  функционировала правильно и без заеданий.
                </p>
                <p>
                  Навесьте дверь на петли и проверьте, чтобы она свободно
                  открывалась и закрывалась
                </p>
                <p>Убедитесь, что дверь не задевает раму и не скрипит</p>
                <p>
                  Если дверь не открывается или закрывается свободно, возможно,
                  потребуется регулировка петель
                </p>
                <p>Проверьте, чтобы дверь стояла ровно и не имела люфтов</p>
              </li>
              <li>
                <span className="entrance_setup__img">
                  <img src={setupImg_05} alt="Финальная проверка и отделка" />
                </span>
                <span className="entrance_setup__sub">
                  Финальная проверка и отделка
                </span>
                <p>
                  После установки двери необходимо провести финальную проверку и
                  выполнить отделочные работы.
                </p>
                <p>
                  Заполните зазоры между коробкой и стеной монтажной пеной для
                  улучшения изоляции.
                </p>
                <p>Наносите пену равномерно, заполняя все пустоты. </p>
                <p>
                  Установите уплотнители по периметру двери, чтобы предотвратить
                  проникновение воздуха и влаги, а также улучшить теплоизоляцию
                  и звукоизоляцию двери.
                </p>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <section className="container entrance_contacts">
        <h3 className="h2">Где купить в Орле входные двери</h3>
        <div className="entrance_map">
          <YMaps>
            <Map
              state={{
                center: [52.966997, 36.077501],
                zoom: 16,
              }}
              width={"100%"}
              height={"100%"}
              options={{ autoFitToViewport: "always" }}
            >
              <Placemark
                geometry={[52.966997, 36.077501]}
                options={{
                  iconLayout: "default#image",
                  iconImageHref: location_pin,
                  iconImageSize: [68, 71],
                  iconImageOffset: [-34, -71],
                }}
                modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
                properties={{
                  iconCaption: "Комфорт+",
                  baloonContent: "baloonContent",
                  hintContent: `<div class="map-img"><img src="${map_img}"></div>`,
                }}
              />
            </Map>
          </YMaps>
        </div>
      </section>
      <div className="container entrance_text">
        <p>
          Купить входную дверь в Орле можно в <span>Комфорт +</span>. Доставка
          выбранной модели занимает от одной до двух недель.
          <br />
          При установке понравившегося варианта можно сделать дополнительный
          металлический добор.
        </p>
      </div>
    </>
  );
};
export default EntranceDoorsPage;
